.training-card {
    @extend .card;
    height: 100%;

    &.zoom {
        transform: scale(1.1);
    }

    .title {
        @extend .fw-bold;
        color: #6D6C6C;
    }

    .training-item {
        height: $spacer * 4;
        padding-left: 36px;
        position: relative;

        --pseudo-color: #7c7c7c;
        --title-color: #313131;
        --subtitle-color: #777777;



        &.greyed {
            --pseudo-color: #D4D4D4;
            --title-color: #9B9B9B;
            --subtitle-color: #BCBCBC;
        }

        &.complete::before {
            content: "\F26A";
        }

        &.inprogress::before {
            content: "\F288";
        }

        &.notstarted::before {
            content: "\F28A";
        }

        &.finish::before {
            content: url("/ui/static/images/icons/mountain-flag.svg");
        }

        &.finish.greyed::before {
            content: url("/ui/static/images/icons/mountain-flag-greyed.svg");
        }

        &::before {
            position: absolute;
            font-size: $font-size-3;
            left: 0px;
            color: var(--pseudo-color);
            background-color: #ffffff;
            z-index: 2;
        }

        &:not(:last-child)::after {
            content: '';
            height: 44px; //You can change this if you want smaller/bigger borders
            width: 1px;
            position: absolute;
            left: 8px;
            top: 18px; // If you want to set a smaller height and center it, change this value  
            background-color: var(--pseudo-color);

        }

        .title {
            color: var(--title-color);
            text-decoration: underline;
            font-size: $font-size-1;
            display: block;
            margin-bottom: $spacer * 0.5;
        }

        .subtitle {
            font-size: 10px;
            color: var(--subtitle-color);
            display: block;

        }
    }
}