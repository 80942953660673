// Task List

.task-list {
    @extend .list-group;
    width: 530px;

    @media (max-width: 768px) {
        width: auto;
    }

    .task-list-item {
        @extend .list-group-item;
        display: flex;
        padding: 20px 24px;

        &::before {
            content: "\F28A";
            color: #b6b6b6;
            margin: 6px 12px 12px 0;
        }

        &.complete {
            &::before {
                content: "\F26A";
                color: $success;
            }
        }

        &.incomplete {
            &::before {
                content: "\F288";
                color: $info;
            }
        }

        .content {
            width: calc(100% - 200px);

            .title {
                font-size: 18px;
                display: block;
                color: #535353;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }


        .subtitle {
            display: block;
            font-size: 12px;
            color: #7c7c7c;
        }
    }
}

.task-item-action {
    margin-left: auto;
    text-decoration: none;
    font-size: $font-size-2;
}
